import React, { useCallback, memo, useRef } from "react";
import Header from "./components/header";
import GameTable from "./components/GameTable";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import './styles/App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import io from 'socket.io-client';

// Msal imports
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError, PublicClientApplication, AccountInfo, Configuration } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { callMsGraph } from "./utils/MsGraphApiCall";

library.add(fas, far, faTwitter, faFontAwesome)


  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // TODO
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //
  // der Websocket muss nach einem Channel join die Channel ID zurückgeben. Diese wird dann im gameID state gespeichert
  //
  // alle manipulationen am player stat im GameTable.jsx müssen über den Websocket gelöst werden
  //
  // wenn der gameStatus "finished" ist, müssen alle Websocket commands bis auf "new game" ignoriert werden
  //
  // Wenn der Raum voll ist, joinen verhindern und als Alert ausgeben 
  //
  // Scope in der authConfig.js anpassen



const App = () => {

  /***
   *     __  ___      ___  ___  __  
   *    /__`  |   /\   |  |__  /__` 
   *    .__/  |  /~~\  |  |___ .__/ 
   *                                
   */

  const { instance, inProgress, accounts } = useMsal();
  const [graphData, setGraphData] = React.useState({displayName: ""});
  const [gameStatus, setGameStatus] = React.useState("waiting");   // waiting, running, finished
  const [showInviteModal, setShowInviteModal] = React.useState(false);
  const [gameID, setGameID] = React.useState(""); // TODO: Abruf beim Websocket connect
  const [playerID, setPlayerID] = React.useState("");  // TODO: Abruf beim Websocket connect
  const [players, setPlayers] = React.useState([]); 
  const inputRef = useRef();

  const [inviteLink, setInviteLink] = React.useState(false);

  /***
   *          __   ___     ___  ___  ___  ___  __  ___  __  
   *    |  | /__` |__     |__  |__  |__  |__  /  `  |  /__` 
   *    \__/ .__/ |___    |___ |    |    |___ \__,  |  .__/ 
   *                                                        
   */

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Abruf der Azure AD Graph Daten
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  React.useEffect(() => {
    function handleUnload(event) {
      const playerIndex = players.findIndex((player) => player.playername === graphData.displayName); 
      socket.emit('leaveRoom', gameID, players[playerIndex].playerid);
    }
    
    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  })


  React.useEffect(() => {
    
    callMsGraph()
    .then(response => 
      { 
        setGraphData(response)
        const params = new URLSearchParams(window.location.search) // id=123
        let id = params.get('id')
        
        if(id !== null) {
          setGameID(id);          
          setInviteLink(true);
        }
      })
      
    .catch((e) => {
        if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
                ...loginRequest,
                account: instance.getActiveAccount()
            });
        }
    });

  }, []);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Get ID Token
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    const getIDToken = useCallback(() => {

      let idToken = ""
      let accessToken = ""
      let accessTokenE2E = ""
      let accessTokenMS = ""
      let refreshToken = ""
  
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
  
        if(key.includes("accesstoken")){
          const lsObject = JSON.parse(localStorage.getItem(key))
          if (lsObject.target === "api://ac67ef6f-69d9-4ad1-9c6c-5a54d02bac02/e2e.all") {
            accessTokenE2E = lsObject.secret;
            //console.log("getting e2e accesstoken: ", accessTokenE2E);
          } else {
            accessTokenMS = lsObject.secret;
          }
          //console.log("lsObjectMS: ", accessTokenMS);
          //console.log("lsObjectE2E: ", accessTokenE2E);
          accessToken = lsObject.secret;
          // console.log("Access Token: " + accessToken);
        } else if(key.includes("refreshtoken")){
          const lsObject = JSON.parse(localStorage.getItem(key))
          refreshToken = lsObject.secret;
          //console.log("Refresh Token: " + lsObject.secret);        
        } else if(key.includes("idtoken")){
          const lsObject = JSON.parse(localStorage.getItem(key))
          idToken = lsObject.secret;
          //console.log("Gathered Token from LS: " + idToken)
        }      
  
      }    
      return(accessTokenE2E)
  
    }, [])

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Web Socket Handling
  // Bereitstellung der Websocket Daten und Funktionen
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const [socket, setSocket] = React.useState(null);
  
  React.useEffect(() => {
    const idToken = getIDToken();
    // Erstellen Sie eine neue WebSocket-Verbindung
    const socketIo = io(process.env.REACT_APP_BACKEND_SVC, {
      path: "/api/socket.io",
      auth: {
        token: idToken
      }
    });

    setSocket(socketIo);

    socketIo.on('connect', function() {
      console.log('WebSocket is connected.');
    });

    socketIo.on('message', function(data) {
        console.log('WebSocket message received:', data);
      });

      socketIo.on('players', function(data) {
        console.log('WebSocket players received:', data);
        setPlayers(data);
      });

      socketIo.on('roomId', function(data) {
        console.log('WebSocket roomid received:', data);
        setGameID(data);
      });

    socketIo.on('createRoom', function(data,data2,data3) {
      console.log(data2);
    });

    socketIo.on('joinRoom', function(data) {
      console.log(data);
    });
    
    socketIo.on('gameState', function(data) {
      console.log('WebSocket gamestate received:', data);
      setGameStatus(data);
    });

    socketIo.on('disconnect', function() {
      console.log('WebSocket is closed.');
      socket.emit('disconnect');
    });

    socketIo.on('error', function(error) {
      console.log('WebSocket Error: ', error);
    });

    // event handler to disconnect the socket when the browser is closed
    /*window.addEventListener('beforeunload', () => {
      console.log("Event listener fired")
      socketIo.disconnect();
    });
    */
   
    return () => {
      // Clean up the event handler when the component is unmounted
      window.removeEventListener('beforeunload', () => {
        socketIo.disconnect();
      });
      socketIo.disconnect();
    };
  }, []);


  React.useEffect(() => {
    console.log("inviteLink: ", inviteLink)
    if(inviteLink){
      joinRoom(graphData.displayName,gameID, graphData.id);
    }
  }, [inviteLink]);


  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Alert Box Handling
  // Bereitstellung von Alert Boxen die mit den Parametern 'type' und 'message' befüllt werden können
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const notify = useCallback((param) => {

    const notifyInfo = (message) => toast.info(message, {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

    const notifyInfoPerm = (message) => toast.success(message, {
      position: "bottom-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark",
    });

    const notifySuccess = (message) => toast.success(message, {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    }); 
    
    const notifyError = (message) => toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    }); 

    const notifyErrorLong = (message) => toast.error(message, {
      position: "bottom-right",
      autoClose: 50000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });     

    const notifyWarn = (message) => toast.warn(message, {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });    

    switch (param.type) {
      case 'alert':
        notifyError(param.message)
        break;
      case 'alertLong':
          notifyErrorLong(param.message)
          break;        
      case 'warning':
        notifyWarn(param.message)
        break;
      case 'success':
        notifySuccess(param.message)
      break; 
      case 'infoPerm':
        notifyInfoPerm(param.message)
      break;              
      default:
        notifyInfo(param.message)
    }
  }, [])


  /***
   *     ___            __  ___    __        __  
   *    |__  |  | |\ | /  `  |  | /  \ |\ | /__` 
   *    |    \__/ | \| \__,  |  | \__/ | \| .__/ 
   *                                             
   */

    // -----------------------------------------------------------
    // Websocket Funktion zum Erstellen eines neuen Spiels
    // -----------------------------------------------------------  
    const createRoom = (name, msId) => {
      console.log("creating room")
      if (socket) {
        socket.emit('createRoom',name, msId);
        setGameStatus("running");
      }
    };

    //Die Socket Funktionen hier drinne sind gefährlich. Sie verhindern das Flackern, aber können auch getriggert werden, 
    //wenn die Funktion selber nicht aufgerufen wird, sondern nur die message getriggert wird. Das sollte nie vorkommen, aber 
    //falls es Phänomene gibt, da ein Auge drauf haben
    const joinRoom = (name,id, msId) => {
      if (socket) {
        socket.emit('joinRoom',id,name, msId);
    
        socket.on('errorMessage', function(data) {
          if(data === "Username already exists" || data === "Room not found" || data === "Room is full"){
            notify({type: 'alert', message: data});
            setGameStatus("gameover");
            setGameID('');
          }
        })
    
        socket.on('successMessage', function() {
          setGameID(id);
          setGameStatus("running");
        })
      }else{
        console.log("socket not ready");
      }
    }

    const submit = (roomId, playerId, value) => {
      if (socket) {
        console.log("submitting: ", roomId, playerId, value);
        socket.emit('submit',roomId, playerId, value);
        socket.on(players, function(data) {
          console.log('WebSocket players change received:', data);
          setPlayers(data);
        });
      }
    }

    // -----------------------------------------------------------
    // Component zum Start des Spiels
    // -----------------------------------------------------------  
    const CreateGame = () => {
      return (
        <div className="landingPageWrap">
          <div className="landingPageContentWrap">
            <p className="landingPageText">Willkommen beim freenet Scrum Poker</p>
            <div className="landingPageInput">
              <label className="inputLabel inputLabelStart">Game ID</label>
              <input className="landingPageInputText inputStart" 
                  type="text" 
                  name="gameID"
                  placeholder="Zum Beitreten gültige ID eingeben"
                  ref={inputRef}
              />
              <button className="landingPageBtn smallBtn" onClick={() => joinRoom(graphData.displayName,inputRef.current.value, graphData.id)}>Join</button>
            </div>
            <button className="landingPageBtn" onClick={() => createRoom(graphData.displayName, graphData.id)}>Neues Spiel starten</button>
          </div>
        </div>
      )
    }

    // -----------------------------------------------------------
    // Funktionen zur Spielsteuerung
    // -----------------------------------------------------------      

    const handleJoin = () => {
      const enteredGameID = inputRef.current.value;    
      if(enteredGameID !== "") {
        notify({type: 'success', message: 'Join with ID: ' + enteredGameID})
      } else {
        notify({type: 'alert', message: 'Bitte gib eine gültige ID ein!'})
      }      
      // TODOD decline if Room is full! 
    };

    const createGame = () => {
      setGameStatus("running");
    }

    const finishGame = (gameID) => {
      //setGameStatus("finished");
      socket.emit('reveal',gameID , "finished");
    }    
    
    const newGame = (gameID) => {
      socket.emit('newRound',gameID , "newgame");
    } 

    const resetGameDone = () => {
      setGameStatus("running");
    } 

    const toggleInviteModal = () => {
      setShowInviteModal(!showInviteModal);
    }  

   /***
   *     __   __            __   ___ ___       __       
   *    |  \ /  \  |\/|    |__) |__   |  |  | |__) |\ | 
   *    |__/ \__/  |  |    |  \ |___  |  \__/ |  \ | \| 
   *                                                    
   */
  return (
    <div className="App">
      <ToastContainer />
      <Header 
        displayName={graphData.displayName}
        gameStatus={gameStatus}
        handleInviteModal={toggleInviteModal}
        showInviteModal={showInviteModal}
        handleNotify={notify}
        gameID={gameID}
      />
      {gameStatus === "running" || gameStatus === "finished" || gameStatus === "newgame" ? 
        <GameTable 
          displayName={graphData.displayName}
          handleInviteModal={toggleInviteModal}
          showInviteModal={showInviteModal}
          handleNotify={notify}
          handleFinishGame={finishGame}
          handleNewGame={newGame}
          handleResetGameDone={resetGameDone}
          gameStatus={gameStatus}
          gameID={gameID}
          players={players}
          setPlayers={setPlayers}
          submit={submit}
        />
        :
        <CreateGame 
          handleNotify={notify}
        />      
      }        
    </div>
  );
}



// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// App Wrap zwecks SSO / Authentifizierung
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
const AppWrap = () => {
  const authRequest = {
      ...loginRequest
  };
  const { accounts } = useMsal(); 
  const isAuthenticated = accounts.length > 0;
  const userId = isAuthenticated ? accounts[0].homeAccountId.split(".")[0] : null;
  
  return (
      <MsalAuthenticationTemplate 
          interactionType={InteractionType.Redirect} 
          authenticationRequest={authRequest} 
      >          
          <App />
      </MsalAuthenticationTemplate>
    )
};

export default memo(AppWrap)
