import React, { useEffect, useState, useRef } from "react";
import { baseBlade } from "../images/blade";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../styles/GameTable.css";

const GameTable = (props) => {   

    /***
     *     __  ___      ___  ___  __  
     *    /__`  |   /\   |  |__  /__` 
     *    .__/  |  /~~\  |  |___ .__/ 
     *                                
     */

  // -----------------------------------------------------------
  // Tracken wie der Status aller Spieler im Raum ist
  // -----------------------------------------------------------  
  //const [players, setPlayers] = React.useState([]);

  //React.useEffect(() => {
  //  setPlayers(props.players);
  //}, [props.players]); // Abhängigkeitsarray

  // -----------------------------------------------------------
  // State zum Tracken was der Client Spieler gerade für einen Status hat
  // -----------------------------------------------------------  
  const [playerStatus, setPlayerStatus] = useState("choosing"); // choosing, waiting, done, gameover


  // -----------------------------------------------------------
  // Fibonnacci Karten
  // -----------------------------------------------------------  
  const [usedCards, setusedCards] = useState([
    { number: "0", selected: false },
    { number: "1", selected: false },
    { number: "2", selected: false },
    { number: "3", selected: false },
    { number: "5", selected: false },
    { number: "8", selected: false },
    { number: "13", selected: false },
    { number: "21", selected: false },
    { number: "34", selected: false },
    { number: "55", selected: false },
    { number: "89", selected: false },
    { number: "?", selected: false },
    { number: "coffee", selected: false }
  ]);
  
  


    /***
     *          __   ___     ___  ___  ___  ___  __  ___  __  
     *    |  | /__` |__     |__  |__  |__  |__  /  `  |  /__` 
     *    \__/ .__/ |___    |___ |    |    |___ \__,  |  .__/ 
     *                                                        
     */


  // -----------------------------------------------------------
  // player Object handling
  // -----------------------------------------------------------      
  useEffect(() => {   

    // -----------------------------------------------------------
    // Tracken welche Spieler bereits gewählt haben
    // -----------------------------------------------------------     

    const cardDivs = document.querySelectorAll('div[card-id]');

      cardDivs.forEach((div, index) => {

        let divIndex = -1;

        props.players.forEach((element, index) => {
          if(div.getAttribute("card-id") === String(element.playerid)){
            divIndex = index;
          }
        });

        if (props.players[divIndex].playervalue !== "none") {
          div.innerHTML = `<div class="GameCardSelected-Backside"><img src=${baseBlade} alt="" width="45" height="45"></img></div>`;
        } else {
          div.innerHTML = `<div class="GameCard-Backside"></div>`;
        }
      });


    // -----------------------------------------------------------
    // Tracken welche Aufgabe der Spieler aktuell hat 
    // -----------------------------------------------------------       
    const isPlayerValueNone = props.players.some(
      (player) => player.playername === props.displayName && player.playervalue !== "none"
    );

    if(props.gameStatus !== "finished"){

    if (isPlayerValueNone) {
      
      if (Object.values(props.players).every(player => player.playervalue !== "none")) {
        setPlayerStatus("done");
      } else {
        //setPlayerStatus("waiting");  // Prod
        setPlayerStatus("done"); // Debug
      }

    } else {
      setPlayerStatus("choosing");  
    }
  }else{
    console.log("Gamestate finished")
  }
  }, [props.players]);


  // -----------------------------------------------------------
  // Zurücksetzen des Spieltisches, ohne zur Landingpage zurück zu müssen
  // -----------------------------------------------------------  
  useEffect(() => {   
    if (props.gameStatus === "finished") {

      const cardDivs = document.querySelectorAll('div[card-id]');

      cardDivs.forEach((div, index) => {

        let divIndex = -1;

        props.players.forEach((element, index) => {
          if(div.getAttribute("card-id") === String(element.playerid)){
            divIndex = index;
          }
        });

        if(props.players[divIndex].playervalue === "coffee"){
          div.innerHTML = `<div class="GameCardSelected-Number"><svg height="50" width="25" viewBox="0 0 512 512"><path fill="#84bc34" d="M88 0C74.7 0 64 10.7 64 24c0 38.9 23.4 59.4 39.1 73.1l1.1 1C120.5 112.3 128 119.9 128 136c0 13.3 10.7 24 24 24s24-10.7 24-24c0-38.9-23.4-59.4-39.1-73.1l-1.1-1C119.5 47.7 112 40.1 112 24c0-13.3-10.7-24-24-24zM32 192c-17.7 0-32 14.3-32 32V416c0 53 43 96 96 96H288c53 0 96-43 96-96h16c61.9 0 112-50.1 112-112s-50.1-112-112-112H352 32zm352 64h16c26.5 0 48 21.5 48 48s-21.5 48-48 48H384V256zM224 24c0-13.3-10.7-24-24-24s-24 10.7-24 24c0 38.9 23.4 59.4 39.1 73.1l1.1 1C232.5 112.3 240 119.9 240 136c0 13.3 10.7 24 24 24s24-10.7 24-24c0-38.9-23.4-59.4-39.1-73.1l-1.1-1C231.5 47.7 224 40.1 224 24z"/></svg></div>`;
        } else if(props.players[divIndex].playervalue !== "none"){
          div.innerHTML = `<div class="GameCardSelected-Number">${props.players[divIndex].playervalue}</div>`;
        } else {
          div.innerHTML = `<div class="GameCardSelected-Number"><svg height="50" width="25" viewBox="0 0 384 512"><path fill="#6d7c97" d="M64 390.3L153.5 256 64 121.7V390.3zM102.5 448H281.5L192 313.7 102.5 448zm128-192L320 390.3V121.7L230.5 256zM281.5 64H102.5L192 198.3 281.5 64zM0 48C0 21.5 21.5 0 48 0H336c26.5 0 48 21.5 48 48V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48z"/></svg></div>`;
        }
        
      });

      setPlayerStatus("gameover");  

    } else if (props.gameStatus === "newgame") {
      props.setPlayers(prevPlayers => prevPlayers.map(player => ({ ...player, playervalue: "none" })));  // TODO: Umbau das dies über den Websocket passiert. 
      setusedCards(prevCards => prevCards.map(card => ({ ...card, selected: false })));

      const cardDivs = document.querySelectorAll('div[card-id]');

      cardDivs.forEach((div, index) => {
        div.innerHTML = `<div class="GameCard-Backside"></div>`;
      });

      props.handleResetGameDone();
    }
  }, [props.gameStatus]);




    /***
     *     ___            __  ___    __        __  
     *    |__  |  | |\ | /  `  |  | /  \ |\ | /__` 
     *    |    \__/ | \| \__,  |  | \__/ | \| .__/ 
     *                                             
     */

    // -----------------------------------------------------------
    // For debug purposes - manually add/remove seats
    // -----------------------------------------------------------
    /*
    const addSeat = () => {
      const newPlayer = {
        playername: props.displayName,
        playervalue: "none"
      };

      props.setPlayers(
        [ ...props.players, newPlayer ]
      )
    }

    const removeSeat = () => {
      props.setPlayers((prevPlayers) => (prevPlayers.slice(0, -1)));
    }
    */
    // -----------------------------------------------------------

    // -----------------------------------------------------------
    // Hinzufügen eines Spielers zum Spieltisch
    // -----------------------------------------------------------     
    const addPlayer = (player) => {

      const playerIndex = props.players.findIndex((p) => p.playername === player.playername);
      // TODO: playerIndex mit PlayerID ersetzen
      if(props.players[playerIndex].playervalue !== "none"){
        return (
          <div className="PlayerWrap">
            <div className="GameCardWrapper">
              <div card-id={props.players[playerIndex].playerid} className="GameCardSelected">
                <div className="GameCardSelected-Backside"><img src={baseBlade} alt="" width="45" heigt="45"></img></div>
              </div>
              <div className="PlayerName">{player.playername}</div>
              {/* <div className="PlayerName">{player.playername.split(' ')[0]}</div> */}
            </div>
          </div>
        )
      } else {
        return (
          <div className="PlayerWrap">
            <div className="GameCardWrapper">
              <div card-id={props.players[playerIndex].playerid} className="GameCard">
                <div className="GameCard-Backside"></div>
              </div>
              <div className="PlayerName">{player.playername}</div>
              {/* <div className="PlayerName">{player.playername.split(' ')[0]}</div> */}
            </div>
          </div>
        )        
      }
    }

    // -----------------------------------------------------------
    // URL zum joinen in die Zwischenablage kopieren
    // -----------------------------------------------------------     
    const copy2clipboard = () => {
      navigator.clipboard.writeText(document.querySelector('.modalContentText').value)
      props.handleInviteModal();
      props.handleNotify({type: 'success', message: 'Link kopiert!'})
    }


    // -----------------------------------------------------------
    // Der User wählt eine Karte aus
    // TODO: statt den Player State direkt zu ändern, muss hier der Websocket angesprochen werden
    // -----------------------------------------------------------     
    const selectCard = (choosenNumber, player) => {
     
      const playerIndex = props.players.findIndex((p) => p.playername === player);
      const newPlayers = [...props.players];

      if (newPlayers[playerIndex].playervalue === choosenNumber) {
        newPlayers[playerIndex] = { ...newPlayers[playerIndex], playervalue: "none" }
      } else {
        newPlayers[playerIndex] = { ...newPlayers[playerIndex], playervalue: choosenNumber }
      }
      
      //props.setPlayers(newPlayers);
      
      //console.log(props.players[playerIndex].playerid, props.gameID, choosenNumber)

      if(props.players[playerIndex].playervalue === choosenNumber){
        choosenNumber = "none";
      }
      
      props.submit(props.gameID, props.players[playerIndex].playerid, choosenNumber)

      setusedCards(prevCards => {
        return prevCards.map(card => {
          if (card.number === choosenNumber) {
            return { ...card, selected: !card.selected };
          } else {
            return { ...card, selected: false };
          }
        });
      });
      
    }

    // -----------------------------------------------------------
    // Aufruf der reveil Cards Funktion im App.js
    // ----------------------------------------------------------- 
    const reveilCards = () => {
      props.handleFinishGame(props.gameID);
    }

        /***
         *     __   __            __   ___ ___       __       
         *    |  \ /  \  |\/|    |__) |__   |  |  | |__) |\ | 
         *    |__/ \__/  |  |    |  \ |___  |  \__/ |  \ | \| 
         *                                                    
         */

  return (

        <div className="GameAreaWrap">

        {props.showInviteModal ? 
          <div className="modalWrapper">
            <div className="modalModule">
              <div className="modalModuleHeader"><button onClick={() => props.handleInviteModal()} className="closeBtn">X</button></div>
              <div className="modalModuleContent">
                <div className="modalContentSpacer"></div>
                <div className="modalContentHeader">Spieler einladen</div>
                <div className="modalContentInput">
                  <label className="inputLabel">Game's url</label>
                  <input className="modalContentText" type="text" readOnly value={process.env.REACT_APP_BACKEND_SVC+"/?id="+props.gameID}></input>
                </div>
                <button className="modalContentBtn" onClick={() => copy2clipboard()}>In Zwischenablage kopieren</button>
              </div>
            </div>
          </div> : null }
          
          {/*<button className="debugButton" onClick={addSeat}>Add Seat</button>
          <button className="debugButton" style={{ left: "140px" }} onClick={removeSeat}>Remove Seat</button>*/}

          <div className="GameTableWrap">
            <div className="GameTable">
              <div></div>
              <div className="Table-Module--top">
                
                {Object.keys(props.players).length > 1 ? 
                  Object.values(props.players).filter((_, index) => [1, 3, 5].includes(index)).map((player, index) => (
                    <div key={index}>{addPlayer(player)}</div>
                  ))
                  :
                  <div className="invitePlayerWrap">Zusammen macht es mehr Spaß:<br></br><button onClick={() => props.handleInviteModal()} className="inviteBtnSpecial">Spieler einladen</button></div>
                }

              </div>
              <div></div>
              <div className="Table-Module--left">
                
                {Object.values(props.players).filter((_, index) => [6, 8].includes(index)).map((player, index) => (
                  <div key={index}>{addPlayer(player)}</div>
                ))}

              </div>
              <div></div>
              <div className="Table-Module--table TableInfos">

                {playerStatus === "choosing" ? (
                      <div>
                        <p>Wähle Deine Karte</p>
                      </div>
                    ) : playerStatus === "waiting" ? (
                      <div>
                        <p>Warte auf andere Spieler</p>
                      </div>
                    ) : playerStatus === "done" ? (
                      <div>
                          <button onClick={() => reveilCards()}>Karten aufdecken</button>
                      </div>
                    ) : playerStatus === "gameover" ? (
                      <div>
                          <button onClick={() => props.handleNewGame(props.gameID)}>Neues Spiel</button>
                      </div>
                    ) : (
                      <></>
                    )
                }  

              </div>
              <div></div>
              <div className="Table-Module--right">
                
                {Object.values(props.players).filter((_, index) => [7, 9].includes(index)).map((player, index) => (
                  <div key={index}>{addPlayer(player)}</div>
                ))}

              </div>
              <div></div>
              <div className="Table-Module--bottom">
                
                {Object.values(props.players).filter((_, index) => [0, 2, 4].includes(index)).map((player, index) => (
                  <div key={index}>{addPlayer(player)}</div>
                ))}
                
              </div>
              <div></div>
            </div>
          </div>

          <div className="GameTableFooter">
          {props.gameStatus === 'finished' ? (
  <div>Startet ein neues Spiel!</div>
) : (
  <>
    <div className="GameTableFooterHeader">Wähle Deine Karte &nbsp; <FontAwesomeIcon icon="fa-regular fa-hand-point-down" /></div>
    <div className="GameTableCards">
      {usedCards.map((obj, index) => (
        <div key={index} className={obj.selected ? "selectedCard" : "notselectedCard"} onClick={() => selectCard(obj.number, props.displayName)}>
          <div className="cardValue">
            {obj.number === "coffee" ? <FontAwesomeIcon icon="fa-solid fa-mug-hot" /> : obj.number}
          </div>
        </div>
      ))}
    </div>
  </>
)}
          </div>
        </div>

  );
};


export default GameTable;